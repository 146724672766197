<template>
  <div class="body-bg">
    <div class="container">
      <van-nav-bar
        title="队伍订单"
        left-arrow
        @click-left="goBack"
        @click-right="goHome"
      >
        <template #right>
          <van-icon
            class="iconfont"
            class-prefix="icon"
            name="index-o"
            size="16"
          />
        </template>
      </van-nav-bar>
      <div class="sts-box">
        <div class="item">
          <p class="title">订单数量</p>
          <p class="money">{{totalNum}}</p>
        </div>
        <div class="line"></div>
        <div class="item">
          <p class="title">总金额</p>
          <p class="money">{{totalMoney}}</p>
        </div>
      </div>
    </div>
    <div class="order-content">
      <div class="tab-top"></div>
      <van-tabs
        active="0"
        color="#F3C301"
        class="tabs"
        title-active-color="#F3C301"
        title-inactive-color="#B3B3B3"
        line-width="40rpx"
        line-height="4rpx"
         @click="changeOrderType"
      >
        <van-tab title="全部" name="0">
          <div class="empty"></div>

          <ul>
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="orderList.length > 0 ? '' : ''"
              @load="loadData(0)"
            >
              <li
                class="order-item-container"
                @click="toDetail(item)"
                v-for="(item, index) in orderList"
                :key="index"
              >
                <div class="order-item-box">
                  <div class="order-item-content">
                    <div class="header-box">
                      <div class="header">
                        <span class="title">{{item.zh_name}}</span>
                        <span class="date spaceL">{{item.create_at}}</span>
                      </div>
                      <van-tag round plain color="#F5C204">已完成</van-tag>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>订单编号:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.order_no}}
                        </p>
                      </div>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.remarks}}
                        </p>
                      </div>
                    </div>

                    <div class="item-2">
                      <div class="item">
                        <div class="title spaceR">
                          <p>代办员:</p>
                        </div>
                        <div class="text spaceL">
                          <p>{{item.staff_name}}</p>
                        </div>
                      </div>
                      <!--
                      <div class="itemR">
                        <div class="title spaceR">
                          <p>车管所:</p>
                        </div>
                        <div class="text spaceL">
                          <p>渝北车管所</p>
                        </div>
                      </div>
                      -->
                    </div>
                    <!--
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>1辆非营运车辆上户；2辆非营运车辆临牌</p>
                      </div>
                    </div>
                    -->
                  </div>
                </div>
                <div class="order-item-footer van-hairline--top">
                  <van-cell-group :border="false">
                    <van-cell class="van-cell-raduis-bottom" center>
                      <!-- 使用 title 插槽来自定义标题 -->
                      <template #title>
                        <div class="priceBox">
                          <p class="priceTitle">总费用:</p>
                           <p class="price spaceL">￥{{item.price_total}}</p>
                        </div>
                      </template>
                    </van-cell>
                  </van-cell-group>
                </div>
              </li>
            </van-list>
          </ul>
        </van-tab>
        <van-tab title="综合服务" name="35">
          <ul>
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="orderList.length > 0 ? '' : ''"
              @load="loadData(35)"
            >
              <li
                class="order-item-container"
                @click="toDetail(item)"
                v-for="(item, index) in orderList"
                :key="index"
              >
                <div class="order-item-box">
                  <div class="order-item-content">
                    <div class="header-box">
                      <div class="header">
                        <span class="title">{{item.zh_name}}</span>
                        <span class="date spaceL">{{item.create_at}}</span>
                      </div>
                      <van-tag round plain color="#F5C204">已完成</van-tag>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>订单编号:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.order_no}}
                        </p>
                      </div>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.remarks}}
                        </p>
                      </div>
                    </div>

                    <div class="item-2">
                      <div class="item">
                        <div class="title spaceR">
                          <p>代办员:</p>
                        </div>
                        <div class="text spaceL">
                          <p>{{item.staff_name}}</p>
                        </div>
                      </div>
                      <!--
                      <div class="itemR">
                        <div class="title spaceR">
                          <p>车管所:</p>
                        </div>
                        <div class="text spaceL">
                          <p>渝北车管所</p>
                        </div>
                      </div>
                      -->
                    </div>
                    <!--
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>1辆非营运车辆上户；2辆非营运车辆临牌</p>
                      </div>
                    </div>
                    -->
                  </div>
                </div>
                <div class="order-item-footer van-hairline--top">
                  <van-cell-group :border="false">
                    <van-cell class="van-cell-raduis-bottom" center>
                      <!-- 使用 title 插槽来自定义标题 -->
                      <template #title>
                        <div class="priceBox">
                          <p class="priceTitle">总费用:</p>
                           <p class="price spaceL">￥{{item.price_total}}</p>
                        </div>
                      </template>
                    </van-cell>
                  </van-cell-group>
                </div>
              </li>
            </van-list>
          </ul>
        </van-tab>
        <van-tab title="汽车代办" name="1">
          <ul>
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="orderList.length > 0 ? '' : ''"
              @load="loadData(1)"
            >
             <li
                class="order-item-container"
                @click="toDetail(item)"
                v-for="(item, index) in orderList"
                :key="index"
              >
                <div class="order-item-box">
                  <div class="order-item-content">
                    <div class="header-box">
                      <div class="header">
                        <span class="title">{{item.zh_name}}</span>
                        <span class="date spaceL">{{item.create_at}}</span>
                      </div>
                      <van-tag round plain color="#F5C204">已完成</van-tag>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>订单编号:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.order_no}}
                        </p>
                      </div>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.remarks}}
                        </p>
                      </div>
                    </div>

                    <div class="item-2">
                      <div class="item">
                        <div class="title spaceR">
                          <p>代办员:</p>
                        </div>
                        <div class="text spaceL">
                          <p>{{item.staff_name}}</p>
                        </div>
                      </div>
                      <!--
                      <div class="itemR">
                        <div class="title spaceR">
                          <p>车管所:</p>
                        </div>
                        <div class="text spaceL">
                          <p>渝北车管所</p>
                        </div>
                      </div>
                      -->
                    </div>
                    <!--
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>1辆非营运车辆上户；2辆非营运车辆临牌</p>
                      </div>
                    </div>
                    -->
                  </div>
                </div>
                <div class="order-item-footer van-hairline--top">
                  <van-cell-group :border="false">
                    <van-cell class="van-cell-raduis-bottom" center>
                      <!-- 使用 title 插槽来自定义标题 -->
                      <template #title>
                        <div class="priceBox">
                          <p class="priceTitle">总费用:</p>
                          <p class="price spaceL">￥{{item.price_total}}</p>
                        </div>
                      </template>
                    </van-cell>
                  </van-cell-group>
                </div>
              </li>
            </van-list>
          </ul>
        </van-tab>
        <van-tab title="用工咨询" name="33">
          <ul>
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="orderList.length > 0 ? '' : ''"
              @load="loadData(33)"
            >
              <li
                class="order-item-container"
                @click="toDetail(item)"
                v-for="(item, index) in orderList"
                :key="index"
              >
                <div class="order-item-box">
                  <div class="order-item-content">
                    <div class="header-box">
                      <div class="header">
                        <span class="title">{{item.zh_name}}</span>
                        <span class="date spaceL">{{item.create_at}}</span>
                      </div>
                      <van-tag round plain color="#F5C204">已完成</van-tag>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>订单编号:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.order_no}}
                        </p>
                      </div>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.remarks}}
                        </p>
                      </div>
                    </div>

                    <div class="item-2">
                      <div class="item">
                        <div class="title spaceR">
                          <p>代办员:</p>
                        </div>
                        <div class="text spaceL">
                          <p>{{item.staff_name}}</p>
                        </div>
                      </div>
                      <!--
                      <div class="itemR">
                        <div class="title spaceR">
                          <p>车管所:</p>
                        </div>
                        <div class="text spaceL">
                          <p>渝北车管所</p>
                        </div>
                      </div>
                      -->
                    </div>
                    <!--
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>1辆非营运车辆上户；2辆非营运车辆临牌</p>
                      </div>
                    </div>
                    -->
                  </div>
                </div>
                <div class="order-item-footer van-hairline--top">
                  <van-cell-group :border="false">
                    <van-cell class="van-cell-raduis-bottom" center>
                      <!-- 使用 title 插槽来自定义标题 -->
                      <template #title>
                        <div class="priceBox">
                          <p class="priceTitle">总费用:</p>
                           <p class="price spaceL">￥{{item.price_total}}</p>
                        </div>
                      </template>
                    </van-cell>
                  </van-cell-group>
                </div>
              </li>
            </van-list>
          </ul>
        </van-tab>
        <van-tab title="知识产权" name="32">
          <ul>
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="orderList.length > 0 ? '' : ''"
              @load="loadData(32)"
            >
             <li
                class="order-item-container"
                @click="toDetail(item)"
                v-for="(item, index) in orderList"
                :key="index"
              >
                <div class="order-item-box">
                  <div class="order-item-content">
                    <div class="header-box">
                      <div class="header">
                        <span class="title">{{item.zh_name}}</span>
                        <span class="date spaceL">{{item.create_at}}</span>
                      </div>
                      <van-tag round plain color="#F5C204">已完成</van-tag>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>订单编号:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.order_no}}
                        </p>
                      </div>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.remarks}}
                        </p>
                      </div>
                    </div>

                    <div class="item-2">
                      <div class="item">
                        <div class="title spaceR">
                          <p>代办员:</p>
                        </div>
                        <div class="text spaceL">
                          <p>{{item.staff_name}}</p>
                        </div>
                      </div>
                      <!--
                      <div class="itemR">
                        <div class="title spaceR">
                          <p>车管所:</p>
                        </div>
                        <div class="text spaceL">
                          <p>渝北车管所</p>
                        </div>
                      </div>
                      -->
                    </div>
                    <!--
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>1辆非营运车辆上户；2辆非营运车辆临牌</p>
                      </div>
                    </div>
                    -->
                  </div>
                </div>
                <div class="order-item-footer van-hairline--top">
                  <van-cell-group :border="false">
                    <van-cell class="van-cell-raduis-bottom" center>
                      <!-- 使用 title 插槽来自定义标题 -->
                      <template #title>
                        <div class="priceBox">
                          <p class="priceTitle">总费用:</p>
                           <p class="price spaceL">￥{{item.price_total}}</p>
                        </div>
                      </template>
                    </van-cell>
                  </van-cell-group>
                </div>
              </li>
            </van-list>
          </ul>
        </van-tab>
        <van-tab title="助贷咨询" name="31">
          <ul>
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="orderList.length > 0 ? '' : ''"
              @load="loadData(31)"
            >
            <li
                class="order-item-container"
                @click="toDetail(item)"
                v-for="(item, index) in orderList"
                :key="index"
              >
                <div class="order-item-box">
                  <div class="order-item-content">
                    <div class="header-box">
                      <div class="header">
                        <span class="title">{{item.zh_name}}</span>
                        <span class="date spaceL">{{item.create_at}}</span>
                      </div>
                      <van-tag round plain color="#F5C204">已完成</van-tag>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>订单编号:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.order_no}}
                        </p>
                      </div>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.remarks}}
                        </p>
                      </div>
                    </div>

                    <div class="item-2">
                      <div class="item">
                        <div class="title spaceR">
                          <p>代办员:</p>
                        </div>
                        <div class="text spaceL">
                          <p>{{item.staff_name}}</p>
                        </div>
                      </div>
                      <!--
                      <div class="itemR">
                        <div class="title spaceR">
                          <p>车管所:</p>
                        </div>
                        <div class="text spaceL">
                          <p>渝北车管所</p>
                        </div>
                      </div>
                      -->
                    </div>
                    <!--
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>1辆非营运车辆上户；2辆非营运车辆临牌</p>
                      </div>
                    </div>
                    -->
                  </div>
                </div>
                <div class="order-item-footer van-hairline--top">
                  <van-cell-group :border="false">
                    <van-cell class="van-cell-raduis-bottom" center>
                      <!-- 使用 title 插槽来自定义标题 -->
                      <template #title>
                        <div class="priceBox">
                          <p class="priceTitle">总费用:</p>
                           <p class="price spaceL">￥{{item.price_total}}</p>
                        </div>
                      </template>
                    </van-cell>
                  </van-cell-group>
                </div>
              </li>
            </van-list>
          </ul>
        </van-tab>
        <van-tab title="考证办证" name="30">
          <ul>
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="orderList.length > 0 ? '' : ''"
              @load="loadData(30)"
            >
             <li
                class="order-item-container"
                @click="toDetail(item)"
                v-for="(item, index) in orderList"
                :key="index"
              >
                <div class="order-item-box">
                  <div class="order-item-content">
                    <div class="header-box">
                      <div class="header">
                        <span class="title">{{item.zh_name}}</span>
                        <span class="date spaceL">{{item.create_at}}</span>
                      </div>
                      <van-tag round plain color="#F5C204">已完成</van-tag>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>订单编号:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.order_no}}
                        </p>
                      </div>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.remarks}}
                        </p>
                      </div>
                    </div>

                    <div class="item-2">
                      <div class="item">
                        <div class="title spaceR">
                          <p>代办员:</p>
                        </div>
                        <div class="text spaceL">
                          <p>{{item.staff_name}}</p>
                        </div>
                      </div>
                      <!--
                      <div class="itemR">
                        <div class="title spaceR">
                          <p>车管所:</p>
                        </div>
                        <div class="text spaceL">
                          <p>渝北车管所</p>
                        </div>
                      </div>
                      -->
                    </div>
                    <!--
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>1辆非营运车辆上户；2辆非营运车辆临牌</p>
                      </div>
                    </div>
                    -->
                  </div>
                </div>
                <div class="order-item-footer van-hairline--top">
                  <van-cell-group :border="false">
                    <van-cell class="van-cell-raduis-bottom" center>
                      <!-- 使用 title 插槽来自定义标题 -->
                      <template #title>
                        <div class="priceBox">
                          <p class="priceTitle">总费用:</p>
                           <p class="price spaceL">￥{{item.price_total}}</p>
                        </div>
                      </template>
                    </van-cell>
                  </van-cell-group>
                </div>
              </li>
            </van-list>
          </ul>
        </van-tab>
        <van-tab title="企业服务" name="9">
          <ul>
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="orderList.length > 0 ? '' : ''"
              @load="loadData(9)"
            >
              <li
                class="order-item-container"
                @click="toDetail(item)"
                v-for="(item, index) in orderList"
                :key="index"
              >
                <div class="order-item-box">
                  <div class="order-item-content">
                    <div class="header-box">
                      <div class="header">
                        <span class="title">{{item.zh_name}}</span>
                        <span class="date spaceL">{{item.create_at}}</span>
                      </div>
                      <van-tag round plain color="#F5C204">已完成</van-tag>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>订单编号:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.order_no}}
                        </p>
                      </div>
                    </div>
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>
                          {{item.remarks}}
                        </p>
                      </div>
                    </div>

                    <div class="item-2">
                      <div class="item">
                        <div class="title spaceR">
                          <p>代办员:</p>
                        </div>
                        <div class="text spaceL">
                          <p>{{item.staff_name}}</p>
                        </div>
                      </div>
                      <!--
                      <div class="itemR">
                        <div class="title spaceR">
                          <p>车管所:</p>
                        </div>
                        <div class="text spaceL">
                          <p>渝北车管所</p>
                        </div>
                      </div>
                      -->
                    </div>
                    <!--
                    <div class="item">
                      <div class="title spaceR">
                        <p>服务需求:</p>
                      </div>
                      <div class="text spaceL">
                        <p>1辆非营运车辆上户；2辆非营运车辆临牌</p>
                      </div>
                    </div>
                    -->
                  </div>
                </div>
                <div class="order-item-footer van-hairline--top">
                  <van-cell-group :border="false">
                    <van-cell class="van-cell-raduis-bottom" center>
                      <!-- 使用 title 插槽来自定义标题 -->
                      <template #title>
                        <div class="priceBox">
                          <p class="priceTitle">总费用:</p>
                           <p class="price spaceL">￥{{item.price_total}}</p>
                        </div>
                      </template>
                    </van-cell>
                  </van-cell-group>
                </div>
              </li>
            </van-list>
          </ul>
        </van-tab>
      </van-tabs>
      
    </div>
    <div style="height:20px"></div>
  </div>
</template>

<script>
import Service from "@/api/service";
import { Toast } from "vant";
export default {
  data() {
    return {
      totalMoney: 0,
      totalNum: 0,
      orderList: [],
      order_type: 0,
      orderTotalNum: 0,
      loading: false,
      finished: false,
      page: 1,
    };
  },
  async beforeMount() {
    //var token = this.$cookie.get("token");
    /*
    var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
    var params = {
      token: token,
    };
    const resp1 = await Service.getStaffFins(this, params);
    */
    this.loadData(0);
  },
  methods: {
     changeOrderType(name) {
      console.log(name);
      this.orderList = [];
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.loadData(name);
    },
    loadData: function(type) {
      this.getFinsOrderList(type);
      this.page++;
    },
    async getFinsOrderList(type) {
      var params2 = {
        token: this.$cookie.get("token"),
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
        order_type: type,
        page: this.page,
      };
      console.log(params2)
      const resp2 = await Service.getStaffFinsOrder(this, params2);
      if (resp2.data.code == 10000) {
        var list = resp2.data.data.list;
        this.totalNum = resp2.data.data.order_num;
        this.totalMoney = resp2.data.data.order_money;
        this.loading = false;
        if (list == null || list.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }
        // 将新数据与老数据进行合并
        this.orderList = this.orderList.concat(list);
      } else {
        //this.orderList = this.orderList.concat([]);
        Toast(resp2.data.msg);
      }
    },
    goBack: function() {
      this.$router.go(-1);
    },
    goHome: function() {
      this.$router.push("/");
    },
    fmoney(s, n) {
    n = n > 0 && n <= 20 ? n : 2;
    s = parseFloat((s + "").replace(/[^\d\\.-]/g, "")).toFixed(n) + "";
    var l = s
        .split(".")[0]
        .split("")
        .reverse(),
      r = s.split(".")[1];
    var t = "";
    for (var i = 0; i < l.length; i++) {
      t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
    }
    return (
      t
        .split("")
        .reverse()
        .join("") +
      "." +
      r
    );
  },
  },
  
};
</script>

<style scoped>
.body-bg {
  height: 100%;
}
.empty {
  width: 100%;
  height: 6px;
  background-color: #fff;
}
.tab-top {
  width: 100%;
  height: 6px;
  background-color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.van-tab {
  font-size: 13px !important;
}
.van-tab .van-tab__text {
  font-size: 13px !important;
}
.van-tab--active .van-tab__text {
  font-size: 14px !important;
}
.container {
  width: 100%;
  height: 200px;
  background: url("~@/assets/img/team-o-bg.png") no-repeat;
  background-size: 100% 100%;
}
.sts-box {
  padding-top: 24px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.sts-box .line {
  width: 1px;
  height: 50px;
  background: #ffffff;
  opacity: 0.6;
}
.sts-box .item {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.sts-box .item .title {
  color: #333;
  font-size: 13px;
  font-weight: bold;
}
.sts-box .item .money {
  margin-top: 4px;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}
.order-content {
  margin: -50px 8px 0px 8px;
  /*box-shadow: 0px 0px 7px 2px rgba(119, 119, 119, 0.05);*/
  border-radius: 4px;
}
.order-content ul {
  height: 480px;
  overflow: auto;
}
.order-content li {
  margin-top: 6px;
  background-color: #fff;
}
.order-content li:first-child {
  margin-top: 0px;
  background-color: #fff;
}

.order-item-box {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.order-item-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.order-item-box .order-item-content {
  padding: 10px 14px 10px 14px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}

.order-item-content .header-box {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.order-item-content .item {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 8px;
}
.order-item-content .itemR {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: baseline;
  margin-top: 8px;
}
.order-item-content .item-2 {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
}
.order-item-content .item .title p {
  color: #b3b3b3;
  font-size: 12px;
  width: 54px;
  text-align-last: justify;
}
.order-item-footer {
  width: 100%;
}

.order-item-content .item .text p {
  color: #333;
  font-size: 12px;
  font-weight: 400;
}
.order-item-content .header-box {
  width: 100%;
  margin-top: 8px;
}
.header-box .title {
  font-size: 14px;
  color: #333;
}
.header-box .date {
  font-size: 13px;
  color: #333;
}
.priceBox {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.priceTitle {
  font-size: 12px;
  font-weight: 600;
  color: #333;
}
.price {
  font-size: 20px;
  color: #fb6354;
  font-weight: 600;
}

.van-cell-group {
  border-radius: 6px !important;
}
.van-cell-raduis {
  border-radius: 6px !important;
}
.van-cell-raduis-top {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
.van-cell-raduis-bottom {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
</style>
